.ttm {
    --ion-color-primary: #889baf;
    --ion-color-primary-rgb: 136, 155, 175;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255, 255, 255;
    --ion-color-primary-shade: #78889a;
    --ion-color-primary-tint: #94a5b7;

    --ion-color-secondary: #54bcbe;
    --ion-color-secondary-rgb: 84, 188, 190;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255, 255, 255;
    --ion-color-secondary-shade: #4aa5a7;
    --ion-color-secondary-tint: #65c3c5;
}
