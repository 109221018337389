.ion-no-padding {
  --padding-start: 0;
  --padding-end: 0;
  --padding-top: 0;
  --padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  padding-bottom: 0;
}

.ion-padding {
  --padding-start: var(--ion-padding, 16px);
  --padding-end: var(--ion-padding, 16px);
  --padding-top: var(--ion-padding, 16px);
  --padding-bottom: var(--ion-padding, 16px);
  padding-left: var(--ion-padding, 16px);
  padding-right: var(--ion-padding, 16px);
  padding-top: var(--ion-padding, 16px);
  padding-bottom: var(--ion-padding, 16px);
}

@supports (margin-inline-start: 0) or (-webkit-margin-start: 0) {
  .ion-padding {
    padding-left: unset;
    padding-right: unset;
    -webkit-padding-start: var(--ion-padding, 16px);
    padding-inline-start: var(--ion-padding, 16px);
    -webkit-padding-end: var(--ion-padding, 16px);
    padding-inline-end: var(--ion-padding, 16px);
  }
}
.ion-padding-top {
  --padding-top: var(--ion-padding, 16px);
  padding-top: var(--ion-padding, 16px);
}

.ion-padding-start {
  --padding-start: var(--ion-padding, 16px);
  padding-left: var(--ion-padding, 16px);
}

@supports (margin-inline-start: 0) or (-webkit-margin-start: 0) {
  .ion-padding-start {
    padding-left: unset;
    -webkit-padding-start: var(--ion-padding, 16px);
    padding-inline-start: var(--ion-padding, 16px);
  }
}
.ion-padding-end {
  --padding-end: var(--ion-padding, 16px);
  padding-right: var(--ion-padding, 16px);
}

@supports (margin-inline-start: 0) or (-webkit-margin-start: 0) {
  .ion-padding-end {
    padding-right: unset;
    -webkit-padding-end: var(--ion-padding, 16px);
    padding-inline-end: var(--ion-padding, 16px);
  }
}
.ion-padding-bottom {
  --padding-bottom: var(--ion-padding, 16px);
  padding-bottom: var(--ion-padding, 16px);
}

.ion-padding-vertical {
  --padding-top: var(--ion-padding, 16px);
  --padding-bottom: var(--ion-padding, 16px);
  padding-top: var(--ion-padding, 16px);
  padding-bottom: var(--ion-padding, 16px);
}

.ion-padding-horizontal {
  --padding-start: var(--ion-padding, 16px);
  --padding-end: var(--ion-padding, 16px);
  padding-left: var(--ion-padding, 16px);
  padding-right: var(--ion-padding, 16px);
}

@supports (margin-inline-start: 0) or (-webkit-margin-start: 0) {
  .ion-padding-horizontal {
    padding-left: unset;
    padding-right: unset;
    -webkit-padding-start: var(--ion-padding, 16px);
    padding-inline-start: var(--ion-padding, 16px);
    -webkit-padding-end: var(--ion-padding, 16px);
    padding-inline-end: var(--ion-padding, 16px);
  }
}
.ion-no-margin {
  --margin-start: 0;
  --margin-end: 0;
  --margin-top: 0;
  --margin-bottom: 0;
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  margin-bottom: 0;
}

.ion-margin {
  --margin-start: var(--ion-margin, 16px);
  --margin-end: var(--ion-margin, 16px);
  --margin-top: var(--ion-margin, 16px);
  --margin-bottom: var(--ion-margin, 16px);
  margin-left: var(--ion-margin, 16px);
  margin-right: var(--ion-margin, 16px);
  margin-top: var(--ion-margin, 16px);
  margin-bottom: var(--ion-margin, 16px);
}

@supports (margin-inline-start: 0) or (-webkit-margin-start: 0) {
  .ion-margin {
    margin-left: unset;
    margin-right: unset;
    -webkit-margin-start: var(--ion-margin, 16px);
    margin-inline-start: var(--ion-margin, 16px);
    -webkit-margin-end: var(--ion-margin, 16px);
    margin-inline-end: var(--ion-margin, 16px);
  }
}
.ion-margin-top {
  --margin-top: var(--ion-margin, 16px);
  margin-top: var(--ion-margin, 16px);
}

.ion-margin-start {
  --margin-start: var(--ion-margin, 16px);
  margin-left: var(--ion-margin, 16px);
}

@supports (margin-inline-start: 0) or (-webkit-margin-start: 0) {
  .ion-margin-start {
    margin-left: unset;
    -webkit-margin-start: var(--ion-margin, 16px);
    margin-inline-start: var(--ion-margin, 16px);
  }
}
.ion-margin-end {
  --margin-end: var(--ion-margin, 16px);
  margin-right: var(--ion-margin, 16px);
}

@supports (margin-inline-start: 0) or (-webkit-margin-start: 0) {
  .ion-margin-end {
    margin-right: unset;
    -webkit-margin-end: var(--ion-margin, 16px);
    margin-inline-end: var(--ion-margin, 16px);
  }
}
.ion-margin-bottom {
  --margin-bottom: var(--ion-margin, 16px);
  margin-bottom: var(--ion-margin, 16px);
}

.ion-margin-vertical {
  --margin-top: var(--ion-margin, 16px);
  --margin-bottom: var(--ion-margin, 16px);
  margin-top: var(--ion-margin, 16px);
  margin-bottom: var(--ion-margin, 16px);
}

.ion-margin-horizontal {
  --margin-start: var(--ion-margin, 16px);
  --margin-end: var(--ion-margin, 16px);
  margin-left: var(--ion-margin, 16px);
  margin-right: var(--ion-margin, 16px);
}

@supports (margin-inline-start: 0) or (-webkit-margin-start: 0) {
  .ion-margin-horizontal {
    margin-left: unset;
    margin-right: unset;
    -webkit-margin-start: var(--ion-margin, 16px);
    margin-inline-start: var(--ion-margin, 16px);
    -webkit-margin-end: var(--ion-margin, 16px);
    margin-inline-end: var(--ion-margin, 16px);
  }
}
.ion-float-left {
  float: left !important;
}

.ion-float-right {
  float: right !important;
}

.ion-float-start {
  float: left !important;
}

[dir=rtl] .ion-float-start, :host-context([dir=rtl]) .ion-float-start {
  float: right !important;
}

.ion-float-end {
  float: right !important;
}

[dir=rtl] .ion-float-end, :host-context([dir=rtl]) .ion-float-end {
  float: left !important;
}

@media (min-width: 576px) {
  .ion-float-sm-left {
    float: left !important;
  }

  .ion-float-sm-right {
    float: right !important;
  }

  .ion-float-sm-start {
    float: left !important;
  }

  [dir=rtl] .ion-float-sm-start, :host-context([dir=rtl]) .ion-float-sm-start {
    float: right !important;
  }

  .ion-float-sm-end {
    float: right !important;
  }

  [dir=rtl] .ion-float-sm-end, :host-context([dir=rtl]) .ion-float-sm-end {
    float: left !important;
  }
}
@media (min-width: 768px) {
  .ion-float-md-left {
    float: left !important;
  }

  .ion-float-md-right {
    float: right !important;
  }

  .ion-float-md-start {
    float: left !important;
  }

  [dir=rtl] .ion-float-md-start, :host-context([dir=rtl]) .ion-float-md-start {
    float: right !important;
  }

  .ion-float-md-end {
    float: right !important;
  }

  [dir=rtl] .ion-float-md-end, :host-context([dir=rtl]) .ion-float-md-end {
    float: left !important;
  }
}
@media (min-width: 992px) {
  .ion-float-lg-left {
    float: left !important;
  }

  .ion-float-lg-right {
    float: right !important;
  }

  .ion-float-lg-start {
    float: left !important;
  }

  [dir=rtl] .ion-float-lg-start, :host-context([dir=rtl]) .ion-float-lg-start {
    float: right !important;
  }

  .ion-float-lg-end {
    float: right !important;
  }

  [dir=rtl] .ion-float-lg-end, :host-context([dir=rtl]) .ion-float-lg-end {
    float: left !important;
  }
}
@media (min-width: 1200px) {
  .ion-float-xl-left {
    float: left !important;
  }

  .ion-float-xl-right {
    float: right !important;
  }

  .ion-float-xl-start {
    float: left !important;
  }

  [dir=rtl] .ion-float-xl-start, :host-context([dir=rtl]) .ion-float-xl-start {
    float: right !important;
  }

  .ion-float-xl-end {
    float: right !important;
  }

  [dir=rtl] .ion-float-xl-end, :host-context([dir=rtl]) .ion-float-xl-end {
    float: left !important;
  }
}
.ion-text-center {
  text-align: center !important;
}

.ion-text-justify {
  text-align: justify !important;
}

.ion-text-start {
  text-align: start !important;
}

.ion-text-end {
  text-align: end !important;
}

.ion-text-left {
  text-align: left !important;
}

.ion-text-right {
  text-align: right !important;
}

.ion-text-nowrap {
  white-space: nowrap !important;
}

.ion-text-wrap {
  white-space: normal !important;
}

@media (min-width: 576px) {
  .ion-text-sm-center {
    text-align: center !important;
  }

  .ion-text-sm-justify {
    text-align: justify !important;
  }

  .ion-text-sm-start {
    text-align: start !important;
  }

  .ion-text-sm-end {
    text-align: end !important;
  }

  .ion-text-sm-left {
    text-align: left !important;
  }

  .ion-text-sm-right {
    text-align: right !important;
  }

  .ion-text-sm-nowrap {
    white-space: nowrap !important;
  }

  .ion-text-sm-wrap {
    white-space: normal !important;
  }
}
@media (min-width: 768px) {
  .ion-text-md-center {
    text-align: center !important;
  }

  .ion-text-md-justify {
    text-align: justify !important;
  }

  .ion-text-md-start {
    text-align: start !important;
  }

  .ion-text-md-end {
    text-align: end !important;
  }

  .ion-text-md-left {
    text-align: left !important;
  }

  .ion-text-md-right {
    text-align: right !important;
  }

  .ion-text-md-nowrap {
    white-space: nowrap !important;
  }

  .ion-text-md-wrap {
    white-space: normal !important;
  }
}
@media (min-width: 992px) {
  .ion-text-lg-center {
    text-align: center !important;
  }

  .ion-text-lg-justify {
    text-align: justify !important;
  }

  .ion-text-lg-start {
    text-align: start !important;
  }

  .ion-text-lg-end {
    text-align: end !important;
  }

  .ion-text-lg-left {
    text-align: left !important;
  }

  .ion-text-lg-right {
    text-align: right !important;
  }

  .ion-text-lg-nowrap {
    white-space: nowrap !important;
  }

  .ion-text-lg-wrap {
    white-space: normal !important;
  }
}
@media (min-width: 1200px) {
  .ion-text-xl-center {
    text-align: center !important;
  }

  .ion-text-xl-justify {
    text-align: justify !important;
  }

  .ion-text-xl-start {
    text-align: start !important;
  }

  .ion-text-xl-end {
    text-align: end !important;
  }

  .ion-text-xl-left {
    text-align: left !important;
  }

  .ion-text-xl-right {
    text-align: right !important;
  }

  .ion-text-xl-nowrap {
    white-space: nowrap !important;
  }

  .ion-text-xl-wrap {
    white-space: normal !important;
  }
}
.ion-text-uppercase {
  /* stylelint-disable-next-line declaration-no-important */
  text-transform: uppercase !important;
}

.ion-text-lowercase {
  /* stylelint-disable-next-line declaration-no-important */
  text-transform: lowercase !important;
}

.ion-text-capitalize {
  /* stylelint-disable-next-line declaration-no-important */
  text-transform: capitalize !important;
}

@media (min-width: 576px) {
  .ion-text-sm-uppercase {
    /* stylelint-disable-next-line declaration-no-important */
    text-transform: uppercase !important;
  }

  .ion-text-sm-lowercase {
    /* stylelint-disable-next-line declaration-no-important */
    text-transform: lowercase !important;
  }

  .ion-text-sm-capitalize {
    /* stylelint-disable-next-line declaration-no-important */
    text-transform: capitalize !important;
  }
}
@media (min-width: 768px) {
  .ion-text-md-uppercase {
    /* stylelint-disable-next-line declaration-no-important */
    text-transform: uppercase !important;
  }

  .ion-text-md-lowercase {
    /* stylelint-disable-next-line declaration-no-important */
    text-transform: lowercase !important;
  }

  .ion-text-md-capitalize {
    /* stylelint-disable-next-line declaration-no-important */
    text-transform: capitalize !important;
  }
}
@media (min-width: 992px) {
  .ion-text-lg-uppercase {
    /* stylelint-disable-next-line declaration-no-important */
    text-transform: uppercase !important;
  }

  .ion-text-lg-lowercase {
    /* stylelint-disable-next-line declaration-no-important */
    text-transform: lowercase !important;
  }

  .ion-text-lg-capitalize {
    /* stylelint-disable-next-line declaration-no-important */
    text-transform: capitalize !important;
  }
}
@media (min-width: 1200px) {
  .ion-text-xl-uppercase {
    /* stylelint-disable-next-line declaration-no-important */
    text-transform: uppercase !important;
  }

  .ion-text-xl-lowercase {
    /* stylelint-disable-next-line declaration-no-important */
    text-transform: lowercase !important;
  }

  .ion-text-xl-capitalize {
    /* stylelint-disable-next-line declaration-no-important */
    text-transform: capitalize !important;
  }
}
.ion-align-self-start {
  align-self: flex-start !important;
}

.ion-align-self-end {
  align-self: flex-end !important;
}

.ion-align-self-center {
  align-self: center !important;
}

.ion-align-self-stretch {
  align-self: stretch !important;
}

.ion-align-self-baseline {
  align-self: baseline !important;
}

.ion-align-self-auto {
  align-self: auto !important;
}

.ion-wrap {
  flex-wrap: wrap !important;
}

.ion-nowrap {
  flex-wrap: nowrap !important;
}

.ion-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.ion-justify-content-start {
  justify-content: flex-start !important;
}

.ion-justify-content-center {
  justify-content: center !important;
}

.ion-justify-content-end {
  justify-content: flex-end !important;
}

.ion-justify-content-around {
  justify-content: space-around !important;
}

.ion-justify-content-between {
  justify-content: space-between !important;
}

.ion-justify-content-evenly {
  justify-content: space-evenly !important;
}

.ion-align-items-start {
  align-items: flex-start !important;
}

.ion-align-items-center {
  align-items: center !important;
}

.ion-align-items-end {
  align-items: flex-end !important;
}

.ion-align-items-stretch {
  align-items: stretch !important;
}

.ion-align-items-baseline {
  align-items: baseline !important;
}

.ion-hide {
  display: none !important;
}

.ion-hide-up {
  display: none !important;
}

.ion-hide-down {
  display: none !important;
}

@media (min-width: 576px) {
  .ion-hide-sm-up {
    display: none !important;
  }
}
@media (max-width: 575.98px) {
  .ion-hide-sm-down {
    display: none !important;
  }
}
@media (min-width: 768px) {
  .ion-hide-md-up {
    display: none !important;
  }
}
@media (max-width: 767.98px) {
  .ion-hide-md-down {
    display: none !important;
  }
}
@media (min-width: 992px) {
  .ion-hide-lg-up {
    display: none !important;
  }
}
@media (max-width: 991.98px) {
  .ion-hide-lg-down {
    display: none !important;
  }
}
@media (min-width: 1200px) {
  .ion-hide-xl-up {
    display: none !important;
  }
}
@media (max-width: 1199.98px) {
  .ion-hide-xl-down {
    display: none !important;
  }
}
/*# sourceMappingURL=utils.bundle.css.map */
.nswbc {
  --ion-color-primary: #007dc3;
  --ion-color-primary-rgb: 0, 125, 195;
  --ion-color-primary-contrast: #fff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #006eac;
  --ion-color-primary-tint: #1a8ac9;
  --ion-color-secondary: #7ed3f7;
  --ion-color-secondary-rgb: 126, 211, 247;
  --ion-color-secondary-contrast: #fff;
  --ion-color-secondary-contrast-rgb: 0, 0, 0;
  --ion-color-secondary-shade: #6fbad9;
  --ion-color-secondary-tint: #8bd7f8;
  --ion-color-tertiary: #ecfbff;
  --ion-color-tertiary-rgb: 236, 251, 255;
  --ion-color-tertiary-contrast: #000;
  --ion-color-tertiary-contrast-rgb: 0, 0, 0;
  --ion-color-tertiary-shade: #d0dde0;
  --ion-color-tertiary-tint: #eefbff;
  --ion-color-danger: #ff6174;
  --ion-color-danger-rgb: 255, 97, 116;
  --ion-color-danger-contrast: #fff;
  --ion-color-danger-contrast-rgb: 0, 0, 0;
  --ion-color-danger-shade: #e05566;
  --ion-color-danger-tint: #ff7182;
  --ion-font-family: Verdana, Geneva, sans-serif;
}

.nswbc {
  --actassa-icon-font-size: 24px;
}
.nswbc ion-header::after {
  background-color: var(--ion-color-secondary);
  background-image: none;
  bottom: -2px;
  height: 2px;
}
.nswbc ion-menu-button,
.nswbc ion-back-button {
  --color: var(--ion-color-primary);
  --icon-font-size: var(--actassa-icon-font-size);
}
.nswbc ion-menu-button.ios,
.nswbc ion-back-button.ios {
  --icon-padding-start: calc(16px + var(--ion-safe-area-left, 0px));
  --icon-padding-stop: 16px;
}
.nswbc ion-title {
  --color: var(--ion-color-dark);
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
}
.nswbc ion-item {
  --highlight-color-valid: var(--ion-color-primary);
}
.nswbc ion-item[button] {
  --background: var(--ion-color-tertiary);
}
.nswbc ion-label {
  font-size: 16px;
  line-height: 19px;
}
.nswbc ion-label.label-stacked {
  transform: scale(1);
}
.nswbc ion-text {
  font-size: 14px;
  line-height: 17px;
}
.nswbc ion-button {
  --border-radius: var(--actassa-border-radius) !important;
  --box-shadow: none;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  outline: none;
  text-transform: capitalize;
}
.nswbc ion-button.button-medium {
  height: 40px;
}
.nswbc ion-icon {
  font-size: var(--actassa-icon-font-size);
}
.nswbc form ion-list {
  --ion-item-background: transparent;
}
.nswbc form ion-item {
  --border-color: var(--ion-color-secondary);
  --background: transparent;
}
.nswbc form ion-checkbox {
  --border-color: var(--ion-color-secondary);
  --border-color-checked: var(--ion-color-primary);
  --background-checked: var(--ion-color-primary);
}
.nswbc form ion-radio {
  --color: var(--ion-color-secondary);
  --color-checked: var(--ion-color-primary);
}
.nswbc actassa-list-time {
  --actassa-list-time-shift-icon: "assets/nswbc/icons/shift.svg";
}